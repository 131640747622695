import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderFour"
import Footer from "../../component/footer/Footer";

class BlogDetails extends Component{
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal () {
    this.setState({isOpen: true})
  }
  render(){
    return(
      <React.Fragment>
        <PageHelmet pageTitle='Operative Umsetzung der Digitalstrategie für ein Logistikunternehmen' />
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--12" data-black-overlay="7">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">

                <h2 className="title theme-gradient">
                Einrichtung einer cloudbasierten Telefonanlage für ein Automotive Unternehmen
              </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>VEHICL bietet eine innovative Software als SaaS für die Digitalisierung der Automobilindustrie. Auf den Service kann über die Website www.vehicl.de zugegriffen werden, die das Fahrzeugmanagement vereinfacht, Fahrzeugexporte abwickelt und integrierte Fahrzeugtransaktionen durchführt.</p>
                    <h2>Projektergebnis und Kundennutzen</h2>
                    <p className="mt--40 h1">Durch die Abwicklung von Autoexporten verfügt VEHICL über starke internationale Verbindungen und mehr Niederlassungen in Frankreich, Spanien und den Niederlanden. Anfänglich verwendete VEHICL in jedem Büro in 4 Ländern / Regionen traditionelle PBX-Telekommunikationslösungen. Dies führte zu einer großen Anzahl unterschiedlicher Rechnungen. Dies liegt daran, dass jedes Büro normalerweise zwei oder mehr Konten hat, z. B. jeden eingehenden und ausgehenden Anruf, der für eine Festnetzverbindung verwendet wird. Dann gibt es den Handyvertrag. Diese Rechnungen von traditionellen Telekommunikationsanbietern erscheinen zu unterschiedlichen Zeiten.</p>
                    <h2>Herausforderung</h2>
                    <p>Natürlich können sich Preise und Kosten jederzeit ändern. Für das Abrechnungsteam ist es daher äußerst schwierig und zeitaufwändig, die Telekommunikationskosten zu beobachten und zu kontrollieren. IT-Mitarbeiter müssen außerdem Endgeräte für neue Benutzer einrichten oder diese physisch neu konfigurieren. Wann immer es geändert werden muss. Dies erfordert die Kontaktaufnahme mit mehr als 6 Service Desks auf der ganzen Welt, die sich alle in unterschiedlichen Zeitzonen befinden und mehrere Sprachen verwenden. Eine cloud-basierte VoIP-Lösunge war aufgrund ihrer Flexibilität, Funktionalität und Kosteneinsparungen die offensichtliche Wahl.</p>
                    <blockquote className="rn-blog-quote">“ Wir haben alles an einem Ort - einem zentralen Verwaltungspunkt - und ich kann weltweit nach Telefonnummern fragen. Wenn ich eine Telefonnummer in Berlin haben möchte, werde ich sie in dasselbe System wie die Telefonnummer in Tokio einfügen. Ich kann meine gesamte Organisation sehen, einschließlich der geografischen Verteilung der Zahlen. Jetzt haben wir auch einen klaren Überblick über die Kosten, und vor allem gibt es nur wenige Rechnungen. Alles ist im Preis inbegriffen. Mit anderen Worten, der einfache Kaufvorgang erfordert nicht den Kauf anderer Funktionen.”, Dimitry Agranovitch, Geschäftsführer der Vehicl GmbH</blockquote>
                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img className="w-100" src="/assets/images/blog/blog-single-02.jpg" alt="Automotive Dealer"/>
                      </div>
                      <div className="content">
                        <h2>Kundennutzen</h2>
                        <p>Nach der Einführung von VEHICL wurde die Produktivität erheblich verbessert. VEHICL kann mit der gleichen Zahl von Mitarbeitern jetzt 60% mehr der Anrufe bearbeiten. Mit der Ein-Klick-Konfiguration des VoiP Systems kann das Unternehmen schnell expandieren. Das Hinzufügen und Löschen von Benutzern ist sehr einfach, um mit den saisonalen Schwankungen des normalen Zyklus der Modebranche umzugehen. Durch die Vereinfachung der Buchhaltung wird das Kontrollschema verbessert und Kosten gespart, indem die Anzahl der vom Buchhaltungsteam benötigten Stunden erheblich reduziert wird. Der Absender profitiert außerdem von einem zuverlässigen Kommunikationssystem ohne Ausfallzeiten und einem integrierten Disaster Recovery-System. Mit diesem System können mithilfe eines Backup-Systems und eines Backup-Rechenzentrums alle Telefonleitungen und Nebenstellen sofort wieder für die Mitarbeiter bereitgestellt werden.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />

      </React.Fragment>
    )
  }
}
export default BlogDetails;